<template>
  <div class="plan-add">
    <div class="btn preview">
      <el-button type="primary" @click="showPre">预览方案</el-button>
    </div>
    <el-tabs v-model="tabs" :before-leave="handleTab">
      <!-- --------------------------基本信息-------------------- -->
      <el-tab-pane label="基本信息" name="basic">
        <el-form
          label-width="100px"
          :disabled="basicFlag"
          v-loading="dataListLoading"
          class="form-con-center"
        >
          <el-form-item label="方案名称" required>
            <el-input
              v-model="planName"
              placeholder="请输入方案名称"
              style="width: 350px"
            ></el-input>
          </el-form-item>
          <el-form-item label="持续时间" required>
            <el-input
              v-model="executionDay"
              placeholder="请输入持续时间"
              type="number"
              style="width: 350px"
            >
              <template slot="append">&nbsp;天</template>
            </el-input>
          </el-form-item>
          <el-form-item label="方案简介" required>
            <el-input
              v-model="context"
              placeholder="请输入方案简介"
              style="width: 350px"
            ></el-input>
          </el-form-item>
          <el-form-item class="last-el-form-item" style="padding-top: 40px">
            <el-button type="primary" @click="save">保存</el-button>
            <el-button @click="back">取消</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <!-- --------------------------基本信息 /-------------------- -->
      <!-- --------------------------一次性活动 -------------------- -->
      <el-tab-pane label="关键活动" name="once">
        <div class="once">
          <div class="left">
            <el-button
              type="primary"
              style="width: 100%; margin-bottom: 20px"
              @click="addActivity"
              >新增关键活动</el-button
            >
            <!-- <template></template> -->
            <div
              class="card"
              v-for="(card, index) in formList2"
              :key="index"
              v-show="card.leftShow"
              @click.stop="showRight(index)"
            >
              <span class="text">{{ card.activityName }}</span>
              <el-button
                style="float: right; padding: 3px 0; color: #f56c6c"
                type="text"
                @click.stop="delActivity(card)"
                v-points
                >删除</el-button
              >
            </div>
          </div>
          <div class="right">
            <el-form
              v-for="(form, index) in formList"
              :key="index"
              v-show="form.show"
              ref="form"
              :model="form"
              label-width="100px"
              class="form"
              :rules="dataRules"
            >
              <div class="line"></div>
              <div class="content">
                <div class="lie">
                  <el-form-item label="活动名" prop="activityName">
                    <el-input
                      v-model.trim="form.activityName"
                      placeholder="请填写活动名"
                      class="wd200"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="活动类型" prop="activityType">
                    <el-select
                      :disabled="form.flag"
                      placeholder="请选择活动类型"
                      v-model="form.activityType"
                      clearable
                      class="wd200"
                      @change="changeActivityType(index)"
                    >
                      <el-option
                        v-for="(item, index) in typeList"
                        :key="index"
                        :value="item.value"
                        :label="item.name"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="lie">
                  <el-form-item label="前序活动">
                    <el-select
                      placeholder="请选择前序"
                      v-model="form.beforeActivityId"
                      clearable
                      class="wd200"
                    >
                      <el-option
                        v-for="(item, index) in formList2"
                        :key="index"
                        :value="item.id"
                        :label="item.activityName"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    label="距离前序活动时间（天）"
                    prop="beforeActivityTime"
                    class="custom-form-item"
                  >
                    <el-input
                      v-model="form.beforeActivityTime"
                      class="wd200"
                      type="number"
                      oninput="value=value.replace(/[^0-9.]/g,'')"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="lie">
                  <el-form-item label="执行时间" prop="executionTime">
                    <el-time-picker
                      class="wd200"
                      v-model="form.executionTime"
                      placeholder="请选择执行时间"
                      value-format="HH:mm:ss"
                    >
                      <!-- type='date' -->
                    </el-time-picker>
                  </el-form-item>
                  <el-form-item label="活动排序" prop="orderNum">
                    <el-input
                      v-model="form.orderNum"
                      type="number"
                      class="wd200"
                      oninput="value=value.replace(/[^0-9.]/g,'')"
                    ></el-input>
                  </el-form-item>
                </div>
                <div v-if="form.activityType == 6" class="lie">
                  <el-form-item label="窗口期">
                    -
                    <el-input
                      v-model="form.startDay"
                      type="number"
                      class="wd100"
                      oninput="value=value.replace(/[^0-9.]/g,'')"
                    ></el-input
                    >天 &nbsp; ~ &nbsp; +<el-input
                      v-model="form.endDay"
                      type="number"
                      class="wd100"
                      oninput="value=value.replace(/[^0-9.]/g,'')"
                    ></el-input
                    >天
                  </el-form-item>
                </div>
                <!-- <div class="lie">
                  <el-form-item label='距离方案发送时间（天）'  prop='orderNum'>
                    <el-input v-model="form.orderNum" type='number' class='wd200' oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
                  </el-form-item>
                </div>-->
                <el-form-item label="备注">
                  <el-input
                    type="textarea"
                    :rows="3"
                    placeholder="请输入备注"
                    v-model="form.activityRemarks"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="main-cnt">
                <div class="title">
                  <div class="shu"></div>
                  <div class="text">主题内容</div>
                </div>
                <div
                  class="cnt-input"
                  v-if="
                    (form.activityType != 1) &
                    (form.activityType != 4) &
                    (form.activityType != 5) &
                    (form.activityType != 6)
                  "
                >
                  <el-form-item label="活动描述">
                    <el-input
                      type="textarea"
                      placeholder="请填写活动描述"
                      v-model="form.activityDescription"
                    ></el-input>
                  </el-form-item>
                </div>
                <template v-else>
                  <div
                    class="other-type"
                    v-if="!form.flag"
                    @click="addMainCnt(form, index)"
                  >
                    + 新增主题内容
                  </div>

                  <!-- 如果有内容 -->
                  <template v-else>
                    <!-- 第一种活动类型 服药的列表 -->
                    <template v-if="form.activityType == 1">
                      <el-table :data="form.medicineList">
                        <el-table-column
                          prop="drugsSimpleName"
                          label="药品简称"
                        ></el-table-column>
                        <el-table-column
                          prop="drugsUseTypeName"
                          label="用法"
                        ></el-table-column>
                        <el-table-column
                          prop="drugsBaseDose"
                          label="用量"
                        ></el-table-column>
                        <el-table-column
                          prop="drugsUseUnitName"
                          label="单位"
                        ></el-table-column>
                        <el-table-column
                          label="操作"
                          header-align="right"
                          align="right"
                          fixed="right"
                        >
                          <template slot-scope="scope">
                            <el-button
                              type="text"
                              size="small"
                              @click="deleteMed(scope.row, index)"
                              >删除</el-button
                            >
                            <!-- {{scope.row}} -->
                          </template>
                        </el-table-column>
                      </el-table>
                    </template>

                    <template v-else-if="form.activityType == 4">
                      <el-table :data="form.missionaryWorks[0]">
                        <!-- <el-table-column prop='workTypeName' label='宣教一级分类'></el-table-column> -->
                        <!-- <el-table-column prop='workTypeSecName' label='宣教二级分类'></el-table-column> -->
                        <el-table-column
                          prop="workName"
                          label="宣教名称"
                        ></el-table-column>
                        <el-table-column
                          label="操作"
                          header-align="center"
                          align="right"
                        >
                          <template slot-scope="scope">
                            <el-button
                              type="text"
                              size="small"
                              @click="deleteMed(scope.row, index)"
                              >删除</el-button
                            >
                          </template>
                        </el-table-column>
                      </el-table>
                    </template>

                    <template
                      v-else-if="
                        form.activityType == 5 || form.activityType == 6
                      "
                    >
                      <el-table :data="form.indexLibraries">
                        <!-- <el-table-column prop='libraryTypeName' label='随访分类'></el-table-column> -->
                        <el-table-column
                          prop="tableName"
                          label="问卷名称"
                        ></el-table-column>
                        <!-- <el-table-column prop='tableName' label='问卷名称2'></el-table-column> -->
                        <el-table-column
                          label="操作"
                          header-align="right"
                          align="right"
                        >
                          <template slot-scope="scope">
                            <el-button
                              type="text"
                              size="small"
                              @click="deleteMed(scope.row, index, scope.$index)"
                              >删除</el-button
                            >
                          </template>
                        </el-table-column>
                      </el-table>
                    </template>
                  </template>
                  <div
                    class="other-type"
                    v-if="
                      form.flag &&
                      (form.activityType == 5 || form.activityType == 6)
                    "
                    @click="addMainCnt(form, index)"
                  >
                    + 新增主题内容
                  </div>
                </template>
              </div>
              <div class="mt20 mb20" style="text-align: center">
                <el-button @click="cancelActivity(index)">取消</el-button>
                <el-button type="primary" @click="submit(index, 1)" v-points
                  >保存</el-button
                >
              </div>
              <choose-medicine
                v-show="form.dialog"
                ref="chooseMed"
                @medicine="getMed($event, index)"
              ></choose-medicine>
              <choose-question
                v-show="form.qsDialog"
                ref="chooseQs"
                @chooseQs="getOther($event, index)"
                :type="form.activityType"
              ></choose-question>
            </el-form>
          </div>
        </div>
        <!-- <choose-medicine ref='chooseMed2'></choose-medicine> -->
      </el-tab-pane>
      <!-- --------------------------一次性活动 /-------------------- -->
      <el-tab-pane label="周期性活动" name="circle">
        <circle1
          @submit2="circlePush"
          :entityId="diseases"
          :programInfoId="programInfoId"
          :typeList="typeList"
          :circleList="circleList"
          :edit="edit"
        ></circle1>
      </el-tab-pane>
      <!-- 预览 -->
      <!-- <el-tab-pane label="1">123</el-tab-pane> -->
      <preview
        v-show="preview"
        ref="preview"
        :list="allList"
        :executionDay="executionDay"
      ></preview>
    </el-tabs>
  </div>
</template>
<script>
import chooseMedicine from "../components/chooseMedicine";
import chooseQuestion from "../components/chooseQuestion";
import preview from "../components/preview.vue";
import circle1 from "../components/circle.vue";
import Qs from "qs";
export default {
  name: "planAdd",

  components: {
    chooseMedicine,
    chooseQuestion,
    circle1,
    preview,
  },

  data() {
    return {
      preview: false, //预览
      dataListLoading: false,
      tabs: "basic",
      // flag0:false,
      basicFlag: false, //基础信息
      planName: "",
      executionDay: "",
      context: "",
      diseases: "", //病种
      diseasesList: [], //病种列表
      typeList: [], //活动类型列表
      // chooseMedDialog:false,
      // chooseQsDialog:false,
      formList2: [],
      beforeList: [], //前序活动列表
      formList: [
        {
          executionTime: "",
          activityName: "",
          activityType: "", //活动类型
          activityDescription: "",
          beforeActivityId: "",
          beforeActivityTime: 0, //前序活动距离天数
          activityStart: "", //第_天
          activityRemarks: "", //备注
          executionTime: "", //执行时间
          orderNum: "", //活动排序
          startDay: "",
          endDay: "",
          dialog: false, //服药dialog
          qsDialog: false, //qsDialog
          flag: false, //是否有主题内容
          show: true, //右侧展示
          leftShow: false, //左侧是否展示
          medicineList: [],
          missionaryWorks: [],
          indexLibraries: [],
          new: true,
        },
      ],
      programInfoId: "", //方案id
      dataRules: {
        activityName: [
          { required: true, message: "活动名不能为空", trigger: "blur" },
        ],
        activityType: [
          { required: true, message: "请选择活动类型", trigger: "change" },
        ],
        executionTime: [
          { required: true, message: "执行时间不能为空", trigger: "blur" },
        ],
        orderNum: [
          { required: true, message: "活动排序不能为空", trigger: "blur" },
        ],
        activityStart: [
          { required: true, message: "时间不能为空", trigger: "blur" },
        ],
      },
      circleList: [],
      edit: false, //是否为编辑页面
      allList: [], //所有方案活动
    };
  },

  watch: {
    // formList2(v){
    //   console.log(v,formList2);
    //   if(!v.length){
    //     this.form.beforeActivityTime = ''
    //   }
    // }
  },

  created() {},

  async mounted() {
    // await this.getDiseasesList()
    await this.getTypeList();
    let query = this.$route.query;
    if (query.edit) {
      this.edit = true;
      this.dataListLoading = true;
      await this.init(query.id);
    }
    this.getTypeArr();
    const user = JSON.parse(localStorage.getItem("userInfo"));
    let teamsId = "";
    let enertyId = "";
    if (localStorage.currentTeam) {
      teamsId = JSON.parse(localStorage.currentTeam).id;
      enertyId = JSON.parse(localStorage.currentTeam).entityId;
    }
    this.teamsId = teamsId;
  },

  methods: {
    // 编辑时初始化数据
    async init(id) {
      // console.log(id);
      const { data: res } = await this.$http({
        url: this.$http.adornUrl("/programInfo/getProgramInfo"),
        method: "post",
        params: { id: id },
      });
      // console.log(res,'init res');
      if (res.status) {
        this.planName = res.data.programName;
        this.diseases = res.data.enertyId;
        this.executionDay = res.data.executionDay;
        this.context = res.data.context;
        this.programInfoId = res.data.id;
        await this.getActivityList();
      } else {
        this.$message.error("获取信息失败，请重试");
        this.dataListLoading = false;
      }
    },
    changeActivityType(index) {
      this.formList[index].flag = false;
    },
    async getActivityList() {
      const { data: result } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/programActivity/list"),
        method: "post",
        data: { stringParam2: this.programInfoId, intParam1: 999 },
      });
      if (result.status) {
        result.data.forEach((v) => {
          v.leftShow = true;
          v.show = false;
          v.dialog = false;
          v.qsDialog = false;
          // 如果有主题内容--药物信息
          if (v.drugsManageId || v.missionaryWorkId || v.followUpId) {
            v.flag = true;
            if (v.drugsManages) {
              v.medicineList = v.drugsManages;
            }
          } else {
            v.flag = false;
            v.medicineList = [];
          }
          if (v.vfTableInfos) {
            v.indexLibraries = v.vfTableInfos;
          }
          // 如果有主题内容--宣教信息--之前用了其他字段名的列表来装 后面就跟拿到的列表名保持一致好了
          // if(){
          //   v.flag = true
          // }else{
          //   v.flag = false
          // }
        });
        result.data.sort((a, b) => {
          return b.orderNum - a.orderNum;
        });
        let once = result.data.filter((v) => {
          return v.activityMode == 1;
        });
        let circle = result.data.filter((v) => {
          return v.activityMode == 2;
        });
        this.allList = result.data;
        this.circleList = circle;
        this.formList = once;
        this.formList2 = JSON.parse(JSON.stringify(once));
        this.dataListLoading = false;
      }
    },
    back() {
      this.$router.back();
    },
    // 拿到药品一些列表
    getTypeArr() {
      this.$httpAes({
        url: this.$httpAes.adornUrl("/dict/list?type=用法"),
        method: "post",
		data: {
		  stringParam1: '用法',
		
		},
      }).then(({ data }) => {
        sessionStorage.setItem("yfTypeArr", JSON.stringify(data.data));
        //  this.yfTypeArr = data.data;
      });
      this.$httpAes({
        url: this.$httpAes.adornUrl("/dict/list?type=单位"),
        method: "post",
		data: {
		  stringParam1: '单位',
		
		},
      }).then(({ data }) => {
        sessionStorage.setItem("dwTypeArr", JSON.stringify(data.data));

        // this.dwTypeArr = data.data;
      });
    },
    // 新增活动
    addActivity() {
      //判断获取有无创建新的活动
      // let num = 0;
      // console.log(this.formList);
      // if (this.formList.length) {
      //   this.formList.forEach((ele) => {
      //     if (ele.new) {
      //       num++;
      //     }
      //   });
      // }
      // if (num > 0) {
      //   this.$message.error("请先保存本项活动。");
      //   return;
      // }
      let arr = this.formList.filter((chr) => {
        return chr.show;
      });
      if (arr.length == 1) {
        this.$message.error("请先保存本项活动");
      } else {
        this.formList.push({
          executionTime: "",
          activityName: "",
          activityType: "", //活动类型
          activityDescription: "",
          activityStart: "", //第_天
          activityRemarks: "", //备注
          executionTime: "", //执行时间
          orderNum: "", //活动排序
          dialog: false,
          qsDialog: false,
          flag: false,
          show: true,
          leftShow: false,
          medicineList: [],
          missionaryWorks: [],
          indexLibraries: [],
          beforeActivityId: "",
          beforeActivityTime: 0,
          new: true, //是否新增
        });
      }
    },
    // 删除活动
    async delActivity(card) {
      let id = card.id;
      this.$confirm("确定进行[删除活动]操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const ids = [];
          // this.dataListSelections.forEach(v=>{
          //   ids.push(v.id)
          // })
          // const id = ids.join(`,`)

          const { data: res } = await this.$http({
            url: this.$http.adornUrl(`/programActivity/delete?ids=${id}`),
            method: "post",
          });
          console.log(res);
          if (res.status) {
            this.$message.success("删除活动成功");
            this.getActivityList();
            // this.getDataList()
            // let arr = this.formList.filter((v)=> v.id!==card.id)
            // this.formList = arr
          }
        })
        .catch(() => {});
    },
    // 保存方案基本信息
    async save() {
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      const user = JSON.parse(localStorage.getItem("userInfo"));
      if (!this.planName) {
        this.$message.error("请输入方案名称");
        return;
      }
      if (!this.executionDay) {
        this.$message.error("请输入持续时间");
        return;
      }
      if (!this.context) {
        this.$message.error("请填写简介");
        return;
      }
      let obj = {
        enertyId: enertyId,
        programName: this.planName,
        // hospitalId:user.deptPid,
        // deptId:user.deptId,
        executionDay: this.executionDay,
        context: this.context,
        status: 1,
        teamsId: teamsId,
        deptId: enertyId,
      };
      if (this.edit) {
        obj.id = this.$route.query.id;
      }
      console.log(Qs.stringify(obj));
      const { data: res } = await this.$http({
        url: this.$http.adornUrl(
          this.edit ? "/programInfo/update" : "/programInfo/add"
        ),
        method: "post",
        data: Qs.stringify(obj),
      });
      // console.log(res,'save res');
      if (res.status) {
        this.tabs = "once";
        this.basicFlag = true;
        this.programInfoId = res.data.id;
        // this.entityId = res.data.id
      }
    },
    // 保存一次性方案活动信息
    async submit(index, mode) {
      // console.log(this.formList[index]);
      let form = Object.assign({}, this.formList[index]);
      this.$refs.form[index].validate(async (valid) => {
        if (valid) {
          if (form.activityType === 1 && form.medicineList.length === 0) {
            this.$message.error("主题内容不能为空！");
            return;
          }
          if (form.activityType === 4 && form.missionaryWorks.length === 0) {
            this.$message.error("主题内容不能为空！");
            return;
          }
          if (
            (form.activityType === 5 || form.activityType === 6) &&
            form.indexLibraries.length === 0
          ) {
            this.$message.error("主题内容不能为空！");
            return;
          }
          let obj = {
            programInfoId: this.programInfoId,
            entityId: this.diseases,
            activityName: form.activityName,
            activityType: form.activityType,
            activityMode: mode,
            activityStart: form.activityStart,
            executionTime: form.executionTime,
            orderNum: form.orderNum,
            activityRemarks: form.activityRemarks,
            activityDescription: form.activityDescription,
            beforeActivityId: form.beforeActivityId,
            beforeActivityTime: form.beforeActivityTime,
            startDay: form.startDay,
            endDay: form.endDay,
            // id:form.id,
            teamsId: this.teamsId,
          };
          if (form.id) {
            obj.id = form.id;
          }
          if (form.medicineList && form.medicineList.length) {
            obj.drugsManageId = form.medicineList[0].id;
            obj.drugsBaseDose = form.medicineList[0].drugsBaseDose;
            obj.drugsUseType = form.medicineList[0].drugsUseType;
            obj.drugsUseUnit = form.medicineList[0].drugsUseUnit;
          }
          if (form.missionaryWorks && form.missionaryWorks.length) {
            obj.missionaryWorkId = form.missionaryWorks[0].id;
          }
          if (form.indexLibraries && form.indexLibraries.length) {
            let arr = [];
            form.indexLibraries.forEach((item) => {
              arr.push(item.id);
            });
            obj.followUpId = arr.join(",");
          }
          console.log(Qs.stringify(obj), "完成");
          // return
          const { data: res } = await this.$httpAes({
            url: this.$httpAes.adornUrl(
              obj.id ? "/programActivity/update" : "/programActivity/add"
            ),
            method: "post",
            data:obj,
          });
          // console.log(res,'一次性res');
          if (res.status) {
            // this.$message.success(this.edit?'编辑方案活动成功':'添加方案活动成功')
            this.$message.success(res.msg);
            // -------这些操作的前提是没有再次拉取列表 所以才需要自己把编辑和删除的数据进行替换
            // 添加完马上把id放回去 这样再次修改就是编辑
            // this.formList[index].id = res.data.id
            // // 如果是编辑的话则不能直接push 而是替换 没做
            // if(obj.id){
            //   let arr = this.allList
            //   let item = arr.findIndex((v)=>v.id==obj.id)
            //   // console.log(item);
            //   this.$set(this.allList,item,res.data)
            // }else{
            //   this.allList.push(res.data)
            // }
            // this.formList[index].show = false
            // this.formList[index].leftShow = true
            //--------重新拉取列表即可实现
            this.getActivityList();
          } else {
            this.$message.error(res.msg);
          }
        } else {
          return false;
        }
      });
    },
    async getDiseasesList() {
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/dict/list"),
        method: "post",
		data: {
		  stringParam1: '基础病种',
		
		},
     
      });
      // console.log(res,'res')
      this.diseasesList = res.data;
    },
    async getTypeList() {
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/dict/list"),
        method: "post",
		data: {
		  stringParam1: '方案活动类型',
		
		},
       
      });
      // console.log(res,'res')
      this.typeList = res.data;
    },
    addMainCnt(form, index) {
      const type = form.activityType;
      if (type == 1) {
        form.dialog = true;
        // console.log(this.$refs,'refs click');
        let ref = `chooseMed${index}`;
        // let a = eval("this.$refs.chooseMed" +index)
        // console.log(ref,this.$refs[(ref)]);
        this.$nextTick(() => {
          this.$refs.chooseMed[index].init();
          // this.$refs.chooseMed[index].test();
          // this.$refs.chooseMed[index].visible=true;
          // console.log(this.$refs.chooseMed[index].init);
          // this.$refs[ref][0].init();
        });
      } else if (type == 4 || type == 5 || type == 6) {
        form.qsDialog = true;
        sessionStorage.setItem("type", type);

        //  let ref = `chooseQs${index}`
        this.$nextTick(() => {
          // this.$refs.ref.init();
          this.$refs.chooseQs[index].init();
        });
      }
    },
    getMed(val, index) {
      this.formList[index].flag = true;
      let yfArr = JSON.parse(sessionStorage.getItem("yfTypeArr"));
      // [val].forEach(v=>{
      yfArr.forEach((v2) => {
        if (val.drugsUseType == v2.value) {
          val.drugsUseTypeName = v2.name;
        }
      });
      // })
      this.formList[index].medicineList = [val];
    },
    getOther(val, index) {
      this.formList[index].flag = true;
      console.log(val, "val gteOther", index);
      if (this.formList[index].activityType === 4) {
        // this.formList[index].missionaryWorks = [{workTypeName:val.workTypeName,workName:val.workName,item:val}]
        this.formList[index].missionaryWorks = [val];
      } else {
        if (val.length > 10) {
          this.$message.warning("最多添加10个问卷");
        } else {
          this.formList[index].indexLibraries = val;
        }
      }
    },
    deleteMed(row, index, delIndex) {
      console.log(delIndex);
      this.formList[index].flag = false;
      if (this.formList[index].activityType === 1) {
        this.formList[index].medicineList = [];
      } else if (this.formList[index].activityType === 4) {
        this.formList[index].missionaryWorks = [];
      } else {
        let arr = JSON.parse(
          JSON.stringify(this.formList[index].indexLibraries)
        );

        arr.splice(delIndex, 1);

        this.formList[index].indexLibraries = arr;
        this.formList[index].flag = true;
      }
    },
    cancelActivity(index) {
      // 新增的则删除 否则就隐藏
      if (this.formList[index].new && this.edit) {
        this.formList.splice(index, 1);
      } else {
        this.formList[index].show = false;
      }
    },
    showRight(index) {
      // this.formList[index].show = true
      let arr = this.formList.filter((chr) => {
        return chr.show;
      });
      if (arr.length == 1) {
        this.$message.error("请先保存本项活动");
      } else {
        this.formList.forEach((v, i) => {
          if (i == index) {
            v.show = true;
          } else {
            v.show = false;
          }
        });
      }
    },
    circlePush(data) {
      //不再是push而是重新拉取列表实现
      console.log(data, "circle push");
      // 如果是编辑的话则不能直接push 而是替换 没做
      // this.allList.push(data)
      // if(data.id){
      //         let arr = this.allList
      //         let item = arr.findIndex((v)=>v.id==data.id)
      //         this.$set(this.allList,0,res.data)
      //       }else{
      //         this.allList.push(res.data)
      // }
      this.getActivityList();
    },
    showPre() {
      this.$refs.preview.init();
      this.preview = true;
    },
    handleTab(activeName, oldActiveName) {
      // console.log(activeName,oldActiveName,1);
      if (!this.basicFlag && !this.edit && oldActiveName == "basic") {
        this.$message.error("请保存基本信息！");
        return false;
      }
      // if(this.edit&&this.basicFlag&&activeName=='basic'){

      // }else{
      //   return false
      // }
      // if(!this.edit&&!this.basicFlag&&activeName=='once'||activeName=='circle'){
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.plan-add {
  position: relative;
  .search-list {
    display: flex;
    align-items: center;
    .label {
      width: 5%;
    }
  }

  .once {
    display: flex;
    .line {
      margin-top: 35px;
      width: 100%;
      height: 1px;
      background-color: #eee;
      margin-bottom: 20px;
    }
    .content {
      padding: 0 20px;
    }
    .lie {
      // display: flex;
      // justify-content: space-between;
      // margin-bottom: 20px;
      overflow: hidden;
      .el-form-item {
        float: left;
        width: 45%;
        margin-right: 10%;
        &:last-child {
          margin: 0;
        }
      }
      /deep/.el-form-item__label {
        width: 100px !important;
      }
      /deep/.el-form-item__content {
        margin-left: 100px !important;
      }
      .custom-form-item {
        display: flex;
        align-items: flex-start;
        // width: 100%;
        /deep/.el-form-item__label {
          line-height: 1.2em;
        }
        /deep/.el-form-item__content {
          margin-left: 0 !important;
          width: calc(100% - 100px);
        }
      }
    }
    .main-cnt {
      padding: 0 10px;
      .title {
        display: flex;
        padding-bottom: 10px;
        .shu {
          width: 4px;
          height: 20px;
          background: #fbb933;
          border-radius: 5px;
        }
        .text {
          color: #fbb933;
          line-height: 20px;
          margin-left: 10px;
        }
      }
      .cnt-input {
        width: 100%;
        border-top: 1px dotted #4286f1;
        padding-top: 20px;
      }
      .other-type {
        width: 100%;
        height: 40px;
        border: 1px dotted #4286f1;
        border-radius: 5px;
        text-align: center;
        line-height: 40px;
        color: #4286f1;
        cursor: pointer;
      }
    }
  }
  .left {
    margin-left: 5px;
    width: 15%;
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    .card {
      display: flex;
      justify-content: space-between;
      width: 100%;
      min-height: 50px;
      padding: 0 10px;
      font-size: 14px;
      line-height: 50px;
      cursor: pointer;
      margin-bottom: 20px;
      border: 1px solid #eee;
      border-radius: 5px;
      .text {
        width: 80%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .card:hover {
      box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.1);
    }
  }
  .right {
    width: 80%;
  }

  .wd100 {
    width: 100px;
  }
  .wd200 {
    width: 100%;
    /deep/.el-input__inner {
      width: 100% !important;
    }
  }
  .wd300 {
    width: 300px;
  }
  .form {
    width: 100%;
    height: auto;
    border: 1px solid #eee;
    margin-bottom: 20px;
    box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6),
      0 2px 4px 0 rgba(232, 237, 250, 0.5);
    // padding-bottom: 20px;
  }
  .form:first-child {
    margin-top: 0;
  }
  .el-tabs__content {
    overflow: unset;
  }
  .preview {
    position: absolute;
    top: -10px;
    right: 0;
    z-index: 10;
  }
}
</style>
